.modal-answer-cover {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
	transform: scale(0);
	transition: all 0.3s ease-in-out;
	padding-left: 12px;
	padding-right: 12px;
}
.modal-answer-cover--open {
	transform: scale(1);
}
.modal-answer {
	position: relative;
	padding: 25px 20px 15px 15px;
	border-radius: 4px;
	// z-index: 100;
	max-width: 600px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	background-color: #fff;
	min-height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	
	
	// .modal-answer__close
	&__close {
		position: absolute;
		cursor: pointer;
		top: 4px;
		right: 4px;
		width: 20px;
		height: 20px;
		&::before {
			content: "";
			width: 100%;
			height: 1px;
			position: absolute;
			top: 50%;
			left: 0;
			transform: rotateZ(135deg);
			background-color: #000;

		}
		&::after {
			content: "";
			width: 100%;
			height: 1px;
			position: absolute;
			top: 50%;
			left: 0;
			transform: rotateZ(45deg);
			background-color: #000;

		}
	}

	
	// .modal-answer__success
	&__success {
		color: rgb(1, 139, 54);
		text-align: center;
	}
	
	
	// .modal-answer__error
	&__error {
		text-align: center;
		color: rgb(241, 0, 0);
	}
}
