.footer {
	padding: 60px 0;

	
	// .footer__title
	&__title {
		font-family: 'Fira Sans', sans-serif;
		color: $dark-grey;
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		margin-bottom: 16px;
		@media(min-width: 744px) {
			font-size: 24px;
			line-height: 29px;
			margin-bottom: 16px;
		}
		@media(min-width: 1200px) {
			font-size: 28px;
			line-height: 34px;
			margin-bottom: 24px;
		}
	}
	
	
	// .footer__tel
	&__tel {
		display: inline-block;
		font-family: 'Fira Sans', sans-serif;
		font-weight: 500;
		font-size: 28px;
		line-height: 34px;
		text-decoration: none;
		color: $dark-grey;
		margin-bottom: 32px;
		
		@media(min-width: 744px) {
			margin-bottom: 36px;
		}
		@media(min-width: 1200px) {
			margin-bottom: 48px; 
		}

		&:hover {
			color: $brand-red;
			text-decoration: none;
		}
	}
}

.footer-social-icon {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 32px;
	@media(min-width: 744px) {
		margin-bottom: 36px;
	}

	@media(min-width: 1200px) {
		margin-bottom: 48px;
	}
	
	// .footer-social-icon__item
	&__item {
		margin-right: 24px;
	
	}
	*:last-child {
		margin-right: 0;
	}
	
	// .footer-social-icon__link

	&__link {}

	// .footer-social-icon__img

	&__img {}
}

.footer-site-link {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;

	@media(min-width: 744px) {
		max-width: 437px;
	}
	@media(min-width: 1200px) {
		max-width: none;
	}
	
	// .footer-site-link__item
	&__item {
		display: flex;
		align-items: center;
		margin-bottom: 16px;
		flex-basis: 100%;
		&:last-child {
			margin-right: 0;
			margin-bottom: 0;
		}
		
		&:first-child {
			order: 3;
			margin-bottom: 0;
			margin-top: 16px;
			@media(min-width: 1200px) {
				margin-top: 0;
			}
		}
		
		@media(min-width: 744px) {
			flex-basis: auto;
			margin-right: 20px;
			margin-bottom: 0;
		}
		
		@media(min-width: 1200px) {
			// margin-bottom: 0;
			max-width: none;
			margin-right: 24px;
			&:first-child {
				order: 0;
			}
		}

		// .footer-site-link__item--first
		&--first {
			@media(min-width: 744px) {
				margin-top: 36px;
			}
		}
		
		// .footer-site-link__item--second
		&--second {
			@media(min-width: 744px) {
	
			}

		}

		// .footer-site-link__item--third
		&--third {

		}

	}

	
	// .footer-site-link__link
	&__link {
		text-decoration: none;
		color: $dark-grey;
		display: flex;
		align-items: center;
		&:hover {
			color: $brand-red;
			text-decoration: none;
		}
		&:hover .footer-site-link__svg path {
			fill: $brand-red;
		}
	}

	// .footer-site-link__svg

	&__svg {
		margin-right: 8px;
	}
	
}
