.commission-wrap {
	display: flex;
	flex-direction: column;

	@media(min-width: 744px) {
		display: block;
	}
}

.commission {
	padding-top: 95px;
	background-image: url(../images/graphic-2.svg);
	background-position: center top;
	background-repeat: no-repeat;
	background-size: contain;

	@media(min-width: 744px) {
		padding-top: 142px;
	}

	@media(min-width: 1200px) {
		padding-top: 174px;
	}

	@media(min-width: 2560px) {
		background-size: auto;
	}
}

.commission-top {

	margin-bottom: 6px;

	// .commission__title
	&__title {
		margin-bottom: 10px;

		@media(min-width: 744px) {
			margin-bottom: 20px;
		}
	}

	// .commission__subtitle
	&__subtitle {
		max-width: 557px;
	}
}

.commission-midle {
	order: 3;
	display: flex;
	position: relative;
	flex-direction: column;

	@media (min-width: 744px) {
		flex-direction: row;
	}

	// .commission-midle__item
	&__item {
		display: flex;
		align-items: center;
		flex-grow: 1;

		// &:last-child {
		// 	margin-right: 0;
		// }
	}


	// .commission-midle__img
	&__img {
		margin-right: 20px;
		flex-shrink: 0;
	}

	// .commission-midle__img-margin
	&__img-margin {}

	// .commission-midle__text
	&__text {}
}

.commission-midle-first {
	margin-bottom: 28px;

	@media (min-width: 744px) {
		margin-bottom: 0;
		// margin-top: 148px;
		// margin-right: 24px;
		max-width: 249px;
		margin-top: 130px;
	}

	@media (min-width: 1200px) {
		margin-right: 24px;
		margin-top: 148px;
		max-width: 306px;
	}

	// .commission-midle-first__img
	&__img {
		max-width: 40px;

		@media (min-width: 744px) {
			max-width: 48px;
			margin-right: 12px;
		}

		@media (min-width: 1200px) {
			max-width: none;
		}
	}
}

.commission-midle-second {
	// max-width: 340px;
	margin-bottom: 28px;
	// margin-top: 12px;

	@media (min-width: 744px) {
		max-width: 280px;
		margin-top: 55px;
		margin-left: -36px;
		margin-bottom: 0;
	}

	@media (min-width: 1200px) {
		margin-top: 0;
		margin-left: 0;
		max-width: 340px;
	}

	// .commission-midle-second__img
	&__img {
		max-width: 48px;

		@media (min-width: 744px) {
			max-width: 57px;
		}

		@media (min-width: 1200px) {
			max-width: none;
		}
	}
}

.commission-midle-third {
	flex-direction: column;
	max-width: 328px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	margin-bottom: 28px;

	// max-width: 524px;
	@media (min-width: 744px) {
		text-align: left;
		margin-bottom: 0;
		flex-direction: row;
		position: absolute;
		max-width: 280px;
		top: 6px;
		right: -19px;
	}

	@media (min-width: 1200px) {
		top: 0px;
		right: -61px;
		// max-width: none;
		max-width: 524px;
	}

	@media (min-width: 1400px) {
		top: 0px;
		right: -111px;
		// max-width: none;
		max-width: 524px;
	}

	& img:first-child {
		display: none;

		@media (min-width: 744px) {
			display: block;
		}
	}

	& img:last-child {
		display: block;

		@media (min-width: 744px) {
			display: none;
		}
	}

	// .commission-midle-third__img
	&__img {
		margin-bottom: 12px;
		margin-right: 0;

		@media (min-width: 744px) {
			margin-bottom: 0;
			margin-right: 12px;
			max-width: 126px;
		}

		@media (min-width: 1200px) {
			max-width: none;
		}
	}
}

.commission-bottom {
	text-align: center;
	margin-top: 24px;
	margin-bottom: 46px;
	// max-width: 328px;
	max-width: 91vw;

	margin-left: auto;
	margin-right: auto;

	@media (min-width: 744px) {
		max-width: none;
		margin-top: 40px;
		margin-bottom: 25px;
	}

	@media (min-width: 1200px) {
		margin-top: 13px;
		margin-bottom: 44px;
	}
}

.commission-info {
	margin-bottom: 60px;

	@media (min-width: 744px) {
		margin-bottom: 80px;
	}

	@media (min-width: 1200px) {
		margin-bottom: 120px;
	}
}