.main-title {
	margin-bottom: 36px;

	@media(min-width: 744px) {
		margin-bottom: 60px;
	}
	
	@media(min-width: 1200px) {
		margin-bottom: 80px; 
	}
	.title-h1 {
		margin-bottom: 12px;
		@media(min-width: 744px) {
			margin-bottom: 16px;
		}
		@media(min-width: 1200px) {
			margin-bottom: 20px;
		}
	}
	
	.headingstyle {}
}

