.offer {
	display: flex;
	align-items: stretch;
	justify-content: center;
	flex-direction: column;
	margin-bottom: 60px;

	@media(min-width: 744px) {
		flex-direction: row;
		margin-bottom: 80px;
	}

	@media(min-width: 1200px) {
		// margin-bottom: 80px;
	}

	// .offer__item
	&__item {
		display: flex;
		justify-content: center;
		flex-direction: column;
	}

	&__item+&__item {
		@media(min-width: 1200px) {
			margin-left: 24px;
		}
	}

	// .offer__item-first
	&__item-first {
		display: none;

		@media(min-width: 1200px) {
			text-align: right;
			display: flex;
		}
	}

	// .offer__item-second
	&__item-second {
		align-items: center;
		margin-bottom: 24px;

		& img {
			max-width: 250px;
			text-align: center;

			@media(min-width: 744px) {
				max-width: 100%;
			}
		}

		width: 100%;

		@media(min-width: 744px) {
			margin-bottom: 0;
			margin-right: 24px;
			max-width: 297px;
		}

		@media(min-width: 1200px) {
			margin-right: 0;
			max-width: 416px;
		}
	}

	// .offer__item-third
	&__item-third {}

	// .offer__item-mobile
	&__item-mobile {
		display: flex;
		margin-bottom: 32px;

		@media(min-width: 1200px) {
			margin-bottom: 0;
			display: none;
		}
	}

	// .offer__title
	&__title {}


	// .offer__subtitle
	&__subtitle {
		font-family: 'Fira Sans', sans-serif;
		color: $dark-grey;
		font-size: 20px;
		line-height: 24px;

		span {
			font-size: 30px;

			@media(min-width: 772px) {
				font-size: 38px;
			}

			@media(min-width: 1200px) {
				font-size: 48px;
			}
		}

		@media(min-width: 744px) {
			font-size: 24px;
			line-height: 29px;
		}

		@media(min-width: 1200px) {
			text-align: right;
			font-size: 28px;
			line-height: 34px;
		}
	}


	// .offer__text
	&__text {
		margin-top: 4px;

		@media(min-width: 744px) {
			margin-top: 8px;
		}

		@media(min-width: 1200px) {
			margin-top: 12px;
		}
	}


	// .offer__double
	&__double {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}


	// .offer__wrap
	&__wrap {
		margin-bottom: 32px;

		@media(min-width: 1200px) {
			margin-bottom: 0;
		}
	}
}