.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 36px;
	margin-bottom: 82px;

	@media(min-width: 576px) {
		justify-content: space-around;
	}
	@media(min-width: 768px) {
		padding-top: 34px;
	}

	@media(min-width: 1200px) {
		justify-content: space-between;
	}
	@media(min-width: 1400px) {
		padding-top: 60px; 
	}
	
	// .header__logo
	&__logo {
		max-width: 120px;
		@media(min-width: 576px) {
			max-width: none;
			flex-basis: 50%;
		}
		@media(min-width: 1200px) {
			flex-basis: auto;
		}
	}
	
	
	// .header__contacts
	&__contacts {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		@media(min-width: 576px) {
			flex-direction: column;
			flex-basis: 50%;
		}
		@media(min-width: 1200px) {
			flex-direction: row;
			flex-basis: auto
		}
		@media(min-width: 1400px) {
			align-items: center;
		}
	}

	
	// .header__group
	&__group {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		& a {
			text-decoration: none;
			color: $dark-grey;
			&:hover {
				text-decoration: none;
				color: $brand-red;
			}
			&:hover .header__svg path {
				fill: $brand-red;
			}
		}
		& + & {
			margin-top: 16px;
			@media(min-width: 1200px) {
				margin-top: 0;
				margin-left: 24px;
			}
		}

		// .header__group-site
		&-site {
			display: none;

			@media(min-width: 576px) {
				display: flex;
			}
		}
		// .header__group-mail
		&-mail {
			display: none;

			@media(min-width: 576px) {
				display: flex;
			}
		}
	}

	
	// .header__phone
	&__phone {}

	
	// .header__svg
	&__svg {
		margin-right: 8px;
		max-width: 24px;
		max-height: 24px;
		@media(min-width: 744px) {
			max-width: none;
			max-height: none;
		}
	}

	// .header__site
	.header__site {}

	// .header__mail
	&__mail {
		display: none;
		@media(min-width: 576px) {
			display: block;
		}
	}
}
