// .three-steps-wrap {
// 	max-width: 1076px;
// 	margin-left: auto;
// 	margin-right: auto;
// }
.three-steps {
	padding-top: 90px;
	background-image: url(../images/graphic.svg);
	background-repeat: no-repeat;
	background-position: center top;
	background-size: contain;

	@media(min-width: 744px) {
		padding-top: 113px;
	}

	@media(min-width: 1200px) {
		padding-top: 178px;
	}

	@media(min-width: 2560px) {
		background-size: auto;
	}

	// .three-steps__title
	&__title {
		position: relative;
		display: flex;
		align-items: center;
		margin-left: auto;
		margin-right: auto;
		// margin-bottom: 84px;
		padding-left: 57px;
		max-width: 300px;
		margin-bottom: 50px;

		@media(min-width: 744px) {
			max-width: 365px;
			// margin-bottom: 112px;
			margin-bottom: 10px;
		}

		@media(min-width: 1200px) {
			max-width: 419px;
			margin-bottom: 84px;
			margin-left: 0;
			padding-left: 83px;
			// max-width: 390px;
			// text-align-last: left;
			// margin-left: 84px;
		}

		span {
			position: absolute;
			bottom: -23px;
			left: 0px;
			font-family: 'Fira Sans', sans-serif;
			color: $dark-grey;
			font-size: 96px;
			line-height: 115px;

			// margin-right: 8px;
			@media(min-width: 744px) {
				font-size: 120px;
				line-height: 144px;
				left: -14px;
				bottom: -35px;
				// margin-right: 12px;
			}

			@media(min-width: 1200px) {
				left: -6px;
				bottom: -41px;
				font-size: 160px;
				line-height: 192px;
			}
		}
	}


	// .three-steps__one
	&__one {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 60px;

		@media(min-width: 744px) {
			margin-bottom: 44px;
		}

		@media(min-width: 1200px) {
			max-width: 308px;
			margin-left: 31vw;
			margin-bottom: 64px;
		}

		@media(min-width: 1920px) {
			max-width: 308px;
			margin-left: 21vw;
		}

		@media(min-width: 3840px) {
			max-width: 308px;
			margin-left: 11vw;
		}

		&-img {
			@media(min-width: 1200px) {
				position: absolute;
				top: -132px;
				right: -400px;
				max-width: 423px;
			}
		}
	}

	// .three-steps__one-wrap
	&__one-wrap {
		max-width: 182px;

		@media(min-width: 576px) {
			max-width: none;

		}
	}

	// .three-steps__subtitle
	&__subtitle {
		margin-bottom: 16px;
	}


	// .three-steps__text
	&__text {}


	// .three-steps__two
	&__two {
		display: flex;
		align-items: center;
		margin-bottom: 80px;

		@media(min-width: 744px) {
			margin-bottom: 60px;
		}

		@media(min-width: 1200px) {
			margin-bottom: 152px;
		}
	}

	// .three-steps__two-subtitle
	&__two-subtitle {
		margin-bottom: 16px;

		@media(min-width: 744px) {
			margin-bottom: 12px;
		}

		@media(min-width: 1200px) {
			margin-bottom: 16px;
		}
	}

	// .three-steps__two-wrap
	&__two-wrap {
		max-width: 402px;

		@media(min-width: 1200px) {
			max-width: 416px;
		}
	}

	// .three-steps__img
	&__img {
		flex-shrink: 0;
		margin-right: 24px;
		max-width: 133px;

		@media(min-width: 744px) {
			max-width: 169px;
		}

		@media(min-width: 1200px) {
			max-width: 416px;
		}
	}


	// .three-steps__address
	&__address {
		margin-bottom: 60px;

		@media(min-width: 744px) {
			margin-bottom: 80px;
		}

		@media(min-width: 1200px) {
			margin-bottom: 120px;
		}
	}

	// .three-steps__address-link
	&__address-link {
		&:hover {
			color: $dark-grey;
		}
	}
}

.three-steps-three {
	position: relative;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	margin-bottom: 12px;

	@media(min-width: 744px) {
		align-items: center;
		flex-direction: row;
		margin-bottom: 32px;
	}

	@media(min-width: 1200px) {
		margin-bottom: 138px;
	}

	// .three-steps-three__item
	&__item {}

	// .three-steps-three__item-left
	&__item-left {
		order: 1;

		@media(min-width: 744px) {
			order: 0;
			max-width: 297px;
		}

		@media(min-width: 1200px) {
			max-width: 414px;
		}

	}

	// .three-steps-three__item-right
	&__item-right {
		margin-bottom: 24px;
		width: 100%;
		text-align: center;

		img {
			max-width: 278px;

			@media(min-width: 744px) {
				max-width: 100%;
			}
		}

		@media(min-width: 744px) {
			position: absolute;
			top: 2px;
			// right: -99px;
			right: -60px;
			width: auto;
			max-width: 357px;
		}

		@media(min-width: 1200px) {
			max-width: none;
			top: -64px;
			// right: -96px;
			right: -55px;
		}
	}


	// .three-steps-three__title
	&__title {
		margin-bottom: 16px;
	}


	// .three-steps-three__text
	&__text {}
}

.three-steps-three-list {
	list-style-type: none;
	margin: 0;
	padding: 0;

	// .three-steps-three-list__item
	&__item {
		position: relative;
		padding-left: 28px;
		margin-bottom: 16px;

		&:last-child {
			margin-bottom: 0;
		}

		&::before {
			position: absolute;
			top: 7px;
			left: 0;
			width: 12px;
			height: 12px;
			content: "";
			background-color: $dark-grey;

			@media(min-width: 1200px) {
				width: 16px;
				height: 16px;
			}
		}
	}
}