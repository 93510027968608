html,
body {
	min-width: 360px;
}

html {
	font-size: 10px;
	line-height: 1.4;
	// position: relative;
}

html {
	// position: relative;
}
body {
	font-size: 1.6rem; /* 10px * 1.6 = 16px */
	font-family: 'Fira Sans', sans-serif;
}
* {
	box-sizing: border-box;
}
img {
	max-width: 100%;
	height: auto;
}
@font-face {
	font-family: "rouble";
	src: url("/fonts/rouble.otf") format("opentype");
}
.body-scroll {
	overflow-y: hidden;
}
.rub {
	font-family: "rouble";
}
.custom-container {
	padding-left: 12px;
	padding-right: 12px;
	margin-right: auto;
	margin-left: auto;
	width: 100%;
	@media(min-width: 744px) {
		// max-width: 615px;
		max-width: 639px;
	}
	
	@media(min-width: 1200px) {
		max-width: 1172px;
	}
	@media(min-width: 1400px) {
		// max-width: 1296px;
		max-width: 1320px;
	}
}
.container-inner {
	max-width: 1076px;
	margin-left: auto;
	margin-right: auto;
}
.title-h1 {
	font-family: 'Fira Sans', sans-serif; 
	font-size: 28px;
	line-height: 34px;
	color: $dark-grey;
	margin-bottom: 0;
	@media(min-width: 1200px) {
		font-size: 44px;
		line-height: 53px;
	}
}
.title-h2 {
	font-family: 'Fira Sans', sans-serif; 
	font-size: 24px;
	line-height: 29px;
	color: $dark-grey;
	margin-bottom: 0;
	@media(min-width: 744px) {
		font-size: 28px;
		line-height: 34px;
	}
	@media(min-width: 1200px) {
		font-size: 32px;
		line-height: 38px; 
	}
}
.headingstyle {
	font-family: 'Fira Sans', sans-serif;
	font-size: 22px;
	line-height: 26px;
	color: $dark-grey;
	font-weight: 400;
	@media(min-width: 1200px) {
		font-size: 28px;
		line-height: 34px;
	}
}
.subtitle {
	font-family: 'Fira Sans', sans-serif; 
	font-size: 18px;
	line-height: 22px;
	color: $dark-grey;
	font-weight: 400;
	@media(min-width: 744px) {
		font-size: 20px;
		line-height: 24px;
	}

	@media(min-width: 1200px) {
		font-size: 24px;
		line-height: 29px;
	}
}
.main-text {
	font-family: 'Fira Sans', sans-serif; 
	font-size: 16px;
	line-height: 19px;
	color: $dark-grey;
	@media(min-width: 744px) {
		font-size: 18px;
		line-height: 22px;
	}
	@media(min-width: 1200px) {
		font-size: 20px;
		line-height: 24px;
	}
}