.anchor-btn-wrap {
	text-align: center;
	margin-bottom: 80px;
}

.anchor-btn {
	display: inline-block;
	background-color: #BE0000;
	border-radius: 4px;
	padding: 12px;
	color: #fff;
	text-transform: uppercase;
	text-decoration: none;
	white-space: nowrap;
	width: 100%;
	max-width: 306px;

	&:active,
	&:hover {
		text-decoration: none;
		color: #fff;
		background-color: #d40000;
	}
}