html,
body {
  min-width: 360px;
}

html {
  font-size: 10px;
  line-height: 1.4;
}

body {
  font-size: 1.6rem;
  /* 10px * 1.6 = 16px */
  font-family: 'Fira Sans', sans-serif;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

@font-face {
  font-family: "rouble";
  src: url("/fonts/rouble.otf") format("opentype");
}

.body-scroll {
  overflow-y: hidden;
}

.rub {
  font-family: "rouble";
}

.custom-container {
  padding-left: 12px;
  padding-right: 12px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

.container-inner {
  max-width: 1076px;
  margin-left: auto;
  margin-right: auto;
}

.title-h1 {
  font-family: 'Fira Sans', sans-serif;
  font-size: 28px;
  line-height: 34px;
  color: #1F2531;
  margin-bottom: 0;
}

.title-h2 {
  font-family: 'Fira Sans', sans-serif;
  font-size: 24px;
  line-height: 29px;
  color: #1F2531;
  margin-bottom: 0;
}

.headingstyle {
  font-family: 'Fira Sans', sans-serif;
  font-size: 22px;
  line-height: 26px;
  color: #1F2531;
  font-weight: 400;
}

.subtitle {
  font-family: 'Fira Sans', sans-serif;
  font-size: 18px;
  line-height: 22px;
  color: #1F2531;
  font-weight: 400;
}

.main-text {
  font-family: 'Fira Sans', sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: #1F2531;
}

.anchor-btn-wrap {
  text-align: center;
  margin-bottom: 80px;
}

.anchor-btn {
  display: inline-block;
  background-color: #BE0000;
  border-radius: 4px;
  padding: 12px;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  width: 100%;
  max-width: 306px;
}

.anchor-btn:active,
.anchor-btn:hover {
  text-decoration: none;
  color: #fff;
  background-color: #d40000;
}

.commission-wrap {
  display: flex;
  flex-direction: column;
}

.commission {
  padding-top: 95px;
  background-image: url(../images/graphic-2.svg);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: contain;
}

.commission-top {
  margin-bottom: 6px;
}

.commission-top__title {
  margin-bottom: 10px;
}

.commission-top__subtitle {
  max-width: 557px;
}

.commission-midle {
  order: 3;
  display: flex;
  position: relative;
  flex-direction: column;
}

.commission-midle__item {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.commission-midle__img {
  margin-right: 20px;
  flex-shrink: 0;
}

.commission-midle-first {
  margin-bottom: 28px;
}

.commission-midle-first__img {
  max-width: 40px;
}

.commission-midle-second {
  margin-bottom: 28px;
}

.commission-midle-second__img {
  max-width: 48px;
}

.commission-midle-third {
  flex-direction: column;
  max-width: 328px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 28px;
}

.commission-midle-third img:first-child {
  display: none;
}

.commission-midle-third img:last-child {
  display: block;
}

.commission-midle-third__img {
  margin-bottom: 12px;
  margin-right: 0;
}

.commission-bottom {
  text-align: center;
  margin-top: 24px;
  margin-bottom: 46px;
  max-width: 91vw;
  margin-left: auto;
  margin-right: auto;
}

.commission-info {
  margin-bottom: 60px;
}

.footer {
  padding: 60px 0;
}

.footer__title {
  font-family: 'Fira Sans', sans-serif;
  color: #1F2531;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 16px;
}

.footer__tel {
  display: inline-block;
  font-family: 'Fira Sans', sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  text-decoration: none;
  color: #1F2531;
  margin-bottom: 32px;
}

.footer__tel:hover {
  color: #BE0000;
  text-decoration: none;
}

.footer-social-icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 32px;
}

.footer-social-icon__item {
  margin-right: 24px;
}

.footer-social-icon *:last-child {
  margin-right: 0;
}

.footer-site-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.footer-site-link__item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  flex-basis: 100%;
}

.footer-site-link__item:last-child {
  margin-right: 0;
  margin-bottom: 0;
}

.footer-site-link__item:first-child {
  order: 3;
  margin-bottom: 0;
  margin-top: 16px;
}

.footer-site-link__link {
  text-decoration: none;
  color: #1F2531;
  display: flex;
  align-items: center;
}

.footer-site-link__link:hover {
  color: #BE0000;
  text-decoration: none;
}

.footer-site-link__link:hover .footer-site-link__svg path {
  fill: #BE0000;
}

.footer-site-link__svg {
  margin-right: 8px;
}

.form {
  background-color: #1F2531;
  padding: 80px 0;
}

.form__title {
  margin-bottom: 20px;
  color: #FCFBFA;
}

.form__subtitle {
  color: #FCFBFA;
  margin-bottom: 44px;
}

.form__form {
  background-color: #FCFBFA;
  border-radius: 12px;
  padding-top: 32px;
  padding-bottom: 44px;
  padding-right: 16px;
  padding-left: 16px;
}

.form__group {
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
  flex-direction: column;
}

.form__group:last-child {
  margin-bottom: 0;
}

.form__input {
  font-family: 'Fira Sans', sans-serif;
  font-size: 16px;
  line-height: 19px;
  background: #CCD3DD;
  border-radius: 4px;
  border: 0;
  outline: none;
  width: 100%;
  flex-grow: 1;
  color: #1F2531;
  border: 1px solid transparent;
}

.form__input {
  padding: 6px 16px;
}

.form__textarea {
  padding: 5px 16px;
}

.form__label {
  max-width: 160px;
  width: 100%;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 8px;
  cursor: pointer;
}

.form__group-textarea {
  margin-bottom: 34px;
}

.form__group-agree {
  margin-bottom: 36px;
}

.form__group-agree label {
  max-width: none;
  margin-bottom: 0;
}

.form__group-agree input {
  display: none;
}

.form__label-agree {
  position: relative;
  padding-left: 48px;
  cursor: pointer;
}

.form__label-agree:before {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  content: "";
  width: 36px;
  height: 36px;
  background: #CCD3DD;
  border-radius: 4px;
  border: 1px solid transparent;
}

.form__input-agree:checked + .form__label-agree::after {
  position: absolute;
  top: 50%;
  left: 4px;
  transform: translateY(-50%);
  content: "";
  width: 28px;
  height: 20px;
  border-radius: 4px;
  background-image: url(../images/agreement.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.form__textarea {
  max-height: 86px;
  height: 86px;
}

.form__btn {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background-color: #BE0000;
  border-radius: 4px;
  padding: 12px;
  color: #fff;
  text-transform: uppercase;
}

.form__btn:hover {
  background-color: #d40000;
}

.form__btn:disabled {
  background-color: #898c93;
}

.form__err {
  border: 1px solid #BE0000;
}

.form__err-agree {
  position: relative;
}

.form__err-agree:before {
  position: absolute;
  content: "";
  border: 1px solid #BE0000;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 36px;
  margin-bottom: 82px;
}

.header__logo {
  max-width: 120px;
}

.header__contacts {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.header__group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.header__group a {
  text-decoration: none;
  color: #1F2531;
}

.header__group a:hover {
  text-decoration: none;
  color: #BE0000;
}

.header__group a:hover .header__svg path {
  fill: #BE0000;
}

.header__group + .header__group {
  margin-top: 16px;
}

.header__group-site {
  display: none;
}

.header__group-mail {
  display: none;
}

.header__svg {
  margin-right: 8px;
  max-width: 24px;
  max-height: 24px;
}

.header__mail {
  display: none;
}

.main-title {
  margin-bottom: 36px;
}

.main-title .title-h1 {
  margin-bottom: 12px;
}

.modal-answer-cover {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
  padding-left: 12px;
  padding-right: 12px;
}

.modal-answer-cover--open {
  transform: scale(1);
}

.modal-answer {
  position: relative;
  padding: 25px 20px 15px 15px;
  border-radius: 4px;
  max-width: 600px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-answer__close {
  position: absolute;
  cursor: pointer;
  top: 4px;
  right: 4px;
  width: 20px;
  height: 20px;
}

.modal-answer__close::before {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: rotateZ(135deg);
  background-color: #000;
}

.modal-answer__close::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: rotateZ(45deg);
  background-color: #000;
}

.modal-answer__success {
  color: #018b36;
  text-align: center;
}

.modal-answer__error {
  text-align: center;
  color: #f10000;
}

.offer {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 60px;
}

.offer__item {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.offer__item-first {
  display: none;
}

.offer__item-second {
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
}

.offer__item-second img {
  max-width: 250px;
  text-align: center;
}

.offer__item-mobile {
  display: flex;
  margin-bottom: 32px;
}

.offer__subtitle {
  font-family: 'Fira Sans', sans-serif;
  color: #1F2531;
  font-size: 20px;
  line-height: 24px;
}

.offer__subtitle span {
  font-size: 30px;
}

.offer__text {
  margin-top: 4px;
}

.offer__double {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.offer__wrap {
  margin-bottom: 32px;
}

.three-steps {
  padding-top: 90px;
  background-image: url(../images/graphic.svg);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: contain;
}

.three-steps__title {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 57px;
  max-width: 300px;
  margin-bottom: 50px;
}

.three-steps__title span {
  position: absolute;
  bottom: -23px;
  left: 0px;
  font-family: 'Fira Sans', sans-serif;
  color: #1F2531;
  font-size: 96px;
  line-height: 115px;
}

.three-steps__one {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
}

.three-steps__one-wrap {
  max-width: 182px;
}

.three-steps__subtitle {
  margin-bottom: 16px;
}

.three-steps__two {
  display: flex;
  align-items: center;
  margin-bottom: 80px;
}

.three-steps__two-subtitle {
  margin-bottom: 16px;
}

.three-steps__two-wrap {
  max-width: 402px;
}

.three-steps__img {
  flex-shrink: 0;
  margin-right: 24px;
  max-width: 133px;
}

.three-steps__address {
  margin-bottom: 60px;
}

.three-steps__address-link:hover {
  color: #1F2531;
}

.three-steps-three {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 12px;
}

.three-steps-three__item-left {
  order: 1;
}

.three-steps-three__item-right {
  margin-bottom: 24px;
  width: 100%;
  text-align: center;
}

.three-steps-three__item-right img {
  max-width: 278px;
}

.three-steps-three__title {
  margin-bottom: 16px;
}

.three-steps-three-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.three-steps-three-list__item {
  position: relative;
  padding-left: 28px;
  margin-bottom: 16px;
}

.three-steps-three-list__item:last-child {
  margin-bottom: 0;
}

.three-steps-three-list__item::before {
  position: absolute;
  top: 7px;
  left: 0;
  width: 12px;
  height: 12px;
  content: "";
  background-color: #1F2531;
}

/* Floats */

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.clearfix::after,
.clearfix::before {
  content: '';
  display: table;
  clear: both;
}

/* Text align */

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

/* Font weight */

.light {
  font-weight: 300;
}

.normal {
  font-weight: 400;
}

.strong {
  font-weight: 700;
}

@media (min-width: 576px) {
  .header {
    justify-content: space-around;
  }

  .header__logo {
    max-width: none;
    flex-basis: 50%;
  }

  .header__contacts {
    flex-direction: column;
    flex-basis: 50%;
  }

  .header__group-site {
    display: flex;
  }

  .header__group-mail {
    display: flex;
  }

  .header__mail {
    display: block;
  }

  .three-steps__one-wrap {
    max-width: none;
  }
}

@media (min-width: 744px) {
  .custom-container {
    max-width: 639px;
  }

  .title-h2 {
    font-size: 28px;
    line-height: 34px;
  }

  .subtitle {
    font-size: 20px;
    line-height: 24px;
  }

  .main-text {
    font-size: 18px;
    line-height: 22px;
  }

  .commission-wrap {
    display: block;
  }

  .commission {
    padding-top: 142px;
  }

  .commission-top__title {
    margin-bottom: 20px;
  }

  .commission-midle {
    flex-direction: row;
  }

  .commission-midle-first {
    margin-bottom: 0;
    max-width: 249px;
    margin-top: 130px;
  }

  .commission-midle-first__img {
    max-width: 48px;
    margin-right: 12px;
  }

  .commission-midle-second {
    max-width: 280px;
    margin-top: 55px;
    margin-left: -36px;
    margin-bottom: 0;
  }

  .commission-midle-second__img {
    max-width: 57px;
  }

  .commission-midle-third {
    text-align: left;
    margin-bottom: 0;
    flex-direction: row;
    position: absolute;
    max-width: 280px;
    top: 6px;
    right: -19px;
  }

  .commission-midle-third img:first-child {
    display: block;
  }

  .commission-midle-third img:last-child {
    display: none;
  }

  .commission-midle-third__img {
    margin-bottom: 0;
    margin-right: 12px;
    max-width: 126px;
  }

  .commission-bottom {
    max-width: none;
    margin-top: 40px;
    margin-bottom: 25px;
  }

  .commission-info {
    margin-bottom: 80px;
  }

  .footer__title {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 16px;
  }

  .footer__tel {
    margin-bottom: 36px;
  }

  .footer-social-icon {
    margin-bottom: 36px;
  }

  .footer-site-link {
    max-width: 437px;
  }

  .footer-site-link__item {
    flex-basis: auto;
    margin-right: 20px;
    margin-bottom: 0;
  }

  .footer-site-link__item--first {
    margin-top: 36px;
  }

  .form__form {
    padding-top: 44px;
    padding-bottom: 44px;
    padding-right: 55px;
    padding-left: 56px;
  }

  .form__group {
    align-items: center;
    flex-direction: row;
    margin-bottom: 28px;
  }

  .form__input {
    max-width: 416px;
    font-size: 18px;
    line-height: 22px;
  }

  .form__input {
    padding: 6px 16px;
  }

  .form__label {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 22px;
    margin-right: 51px;
  }

  .form__group-textarea {
    margin-bottom: 44px;
  }

  .form__group-agree {
    max-width: 350px;
  }

  .form__btn {
    max-width: 306px;
  }

  .header__svg {
    max-width: none;
    max-height: none;
  }

  .main-title {
    margin-bottom: 60px;
  }

  .main-title .title-h1 {
    margin-bottom: 16px;
  }

  .offer {
    flex-direction: row;
    margin-bottom: 80px;
  }

  .offer__item-second img {
    max-width: 100%;
  }

  .offer__item-second {
    margin-bottom: 0;
    margin-right: 24px;
    max-width: 297px;
  }

  .offer__subtitle {
    font-size: 24px;
    line-height: 29px;
  }

  .offer__text {
    margin-top: 8px;
  }

  .three-steps {
    padding-top: 113px;
  }

  .three-steps__title {
    max-width: 365px;
    margin-bottom: 10px;
  }

  .three-steps__title span {
    font-size: 120px;
    line-height: 144px;
    left: -14px;
    bottom: -35px;
  }

  .three-steps__one {
    margin-bottom: 44px;
  }

  .three-steps__two {
    margin-bottom: 60px;
  }

  .three-steps__two-subtitle {
    margin-bottom: 12px;
  }

  .three-steps__img {
    max-width: 169px;
  }

  .three-steps__address {
    margin-bottom: 80px;
  }

  .three-steps-three {
    align-items: center;
    flex-direction: row;
    margin-bottom: 32px;
  }

  .three-steps-three__item-left {
    order: 0;
    max-width: 297px;
  }

  .three-steps-three__item-right img {
    max-width: 100%;
  }

  .three-steps-three__item-right {
    position: absolute;
    top: 2px;
    right: -60px;
    width: auto;
    max-width: 357px;
  }
}

@media (min-width: 768px) {
  .header {
    padding-top: 34px;
  }
}

@media (min-width: 772px) {
  .offer__subtitle span {
    font-size: 38px;
  }
}

@media (min-width: 1200px) {
  .custom-container {
    max-width: 1172px;
  }

  .title-h1 {
    font-size: 44px;
    line-height: 53px;
  }

  .title-h2 {
    font-size: 32px;
    line-height: 38px;
  }

  .headingstyle {
    font-size: 28px;
    line-height: 34px;
  }

  .subtitle {
    font-size: 24px;
    line-height: 29px;
  }

  .main-text {
    font-size: 20px;
    line-height: 24px;
  }

  .commission {
    padding-top: 174px;
  }

  .commission-midle-first {
    margin-right: 24px;
    margin-top: 148px;
    max-width: 306px;
  }

  .commission-midle-first__img {
    max-width: none;
  }

  .commission-midle-second {
    margin-top: 0;
    margin-left: 0;
    max-width: 340px;
  }

  .commission-midle-second__img {
    max-width: none;
  }

  .commission-midle-third {
    top: 0px;
    right: -61px;
    max-width: 524px;
  }

  .commission-midle-third__img {
    max-width: none;
  }

  .commission-bottom {
    margin-top: 13px;
    margin-bottom: 44px;
  }

  .commission-info {
    margin-bottom: 120px;
  }

  .footer__title {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 24px;
  }

  .footer__tel {
    margin-bottom: 48px;
  }

  .footer-social-icon {
    margin-bottom: 48px;
  }

  .footer-site-link {
    max-width: none;
  }

  .footer-site-link__item:first-child {
    margin-top: 0;
  }

  .footer-site-link__item {
    max-width: none;
    margin-right: 24px;
  }

  .footer-site-link__item:first-child {
    order: 0;
  }

  .form__form {
    padding-top: 60px;
    padding-bottom: 60px;
    padding-right: 10px;
    padding-left: 110px;
    background-image: url(../images/car-form.png), url(../images/vertical-graphic.svg);
    background-repeat: no-repeat;
    background-position: 95% 65%, 98% 50%;
  }

  .form__input {
    font-size: 20px;
    line-height: 24px;
  }

  .form__label {
    font-size: 20px;
    line-height: 24px;
    margin-right: 24px;
    max-width: 196px;
  }

  .form__group-textarea {
    margin-bottom: 28px;
  }

  .form__group-agree {
    max-width: none;
  }

  .header {
    justify-content: space-between;
  }

  .header__logo {
    flex-basis: auto;
  }

  .header__contacts {
    flex-direction: row;
    flex-basis: auto;
  }

  .header__group + .header__group {
    margin-top: 0;
    margin-left: 24px;
  }

  .main-title {
    margin-bottom: 80px;
  }

  .main-title .title-h1 {
    margin-bottom: 20px;
  }

  .offer__item + .offer__item {
    margin-left: 24px;
  }

  .offer__item-first {
    text-align: right;
    display: flex;
  }

  .offer__item-second {
    margin-right: 0;
    max-width: 416px;
  }

  .offer__item-mobile {
    margin-bottom: 0;
    display: none;
  }

  .offer__subtitle span {
    font-size: 48px;
  }

  .offer__subtitle {
    text-align: right;
    font-size: 28px;
    line-height: 34px;
  }

  .offer__text {
    margin-top: 12px;
  }

  .offer__wrap {
    margin-bottom: 0;
  }

  .three-steps {
    padding-top: 178px;
  }

  .three-steps__title {
    max-width: 419px;
    margin-bottom: 84px;
    margin-left: 0;
    padding-left: 83px;
  }

  .three-steps__title span {
    left: -6px;
    bottom: -41px;
    font-size: 160px;
    line-height: 192px;
  }

  .three-steps__one {
    max-width: 308px;
    margin-left: 31vw;
    margin-bottom: 64px;
  }

  .three-steps__one-img {
    position: absolute;
    top: -132px;
    right: -400px;
    max-width: 423px;
  }

  .three-steps__two {
    margin-bottom: 152px;
  }

  .three-steps__two-subtitle {
    margin-bottom: 16px;
  }

  .three-steps__two-wrap {
    max-width: 416px;
  }

  .three-steps__img {
    max-width: 416px;
  }

  .three-steps__address {
    margin-bottom: 120px;
  }

  .three-steps-three {
    margin-bottom: 138px;
  }

  .three-steps-three__item-left {
    max-width: 414px;
  }

  .three-steps-three__item-right {
    max-width: none;
    top: -64px;
    right: -55px;
  }

  .three-steps-three-list__item::before {
    width: 16px;
    height: 16px;
  }
}

@media (min-width: 1400px) {
  .custom-container {
    max-width: 1320px;
  }

  .commission-midle-third {
    top: 0px;
    right: -111px;
    max-width: 524px;
  }

  .header {
    padding-top: 60px;
  }

  .header__contacts {
    align-items: center;
  }
}

@media (min-width: 1920px) {
  .three-steps__one {
    max-width: 308px;
    margin-left: 21vw;
  }
}

@media (min-width: 2560px) {
  .commission {
    background-size: auto;
  }

  .three-steps {
    background-size: auto;
  }
}

@media (min-width: 3840px) {
  .three-steps__one {
    max-width: 308px;
    margin-left: 11vw;
  }
}