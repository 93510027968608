.form {
	background-color: $dark-grey;
	padding: 80px 0;

	// .form__title
	&__title {
		margin-bottom: 20px;
		color: $white;
	}

	// .form__subtitle

	&__subtitle {
		color: $white;
		margin-bottom: 44px;
	}

	// .form__form

	&__form {
		background-color: #FCFBFA;
		border-radius: 12px;
		padding-top: 32px;
		padding-bottom: 44px;
		padding-right: 16px;
		padding-left: 16px;

		@media(min-width: 744px) {
			padding-top: 44px;
			padding-bottom: 44px;
			padding-right: 55px;
			padding-left: 56px;

		}

		@media(min-width: 1200px) {
			padding-top: 60px;
			padding-bottom: 60px;
			padding-right: 10px;
			padding-left: 110px;
			background-image: url(../images/car-form.png), url(../images/vertical-graphic.svg);
			background-repeat: no-repeat;
			background-position: 95% 65%, 98% 50%;
		}
	}


	// .form__group
	&__group {
		display: flex;
		align-items: flex-start;
		margin-bottom: 24px;
		flex-direction: column;

		@media(min-width: 744px) {
			align-items: center;
			flex-direction: row;
			margin-bottom: 28px;
		}

		&:last-child {
			margin-bottom: 0;
		}


	}

	&__input {
		font-family: 'Fira Sans', sans-serif;
		font-size: 16px;
		line-height: 19px;
		background: $light-grey;
		border-radius: 4px;
		border: 0;
		outline: none;
		width: 100%;
		flex-grow: 1;
		color: $dark-grey;
		border: 1px solid transparent;

		@media(min-width: 744px) {
			max-width: 416px;
			font-size: 18px;
			line-height: 22px;
		}

		@media(min-width: 1200px) {
			font-size: 20px;
			line-height: 24px;
		}
	}

	&__input {
		padding: 6px 16px;

		@media(min-width: 744px) {
			padding: 6px 16px;
		}
	}

	&__textarea {
		padding: 5px 16px;
	}

	// .form__label
	&__label {
		max-width: 160px;
		width: 100%;
		font-size: 16px;
		line-height: 19px;
		margin-bottom: 8px;
		cursor: pointer;

		@media(min-width: 744px) {
			margin-bottom: 0;
			font-size: 18px;
			line-height: 22px;
			margin-right: 51px;
		}

		@media(min-width: 1200px) {
			font-size: 20px;
			line-height: 24px;
			margin-right: 24px;
			max-width: 196px;
		}
	}

	// .form__group-textarea
	&__group-textarea {
		margin-bottom: 34px;

		@media(min-width: 744px) {
			margin-bottom: 44px;
		}

		@media(min-width: 1200px) {
			margin-bottom: 28px;
		}
	}

	// .form__group-agree 
	&__group-agree {
		margin-bottom: 36px;

		@media(min-width: 744px) {
			max-width: 350px;
		}

		@media(min-width: 1200px) {
			max-width: none;
		}

		label {
			max-width: none;
			margin-bottom: 0;
		}

		input {
			display: none;
		}
	}



	// .form__label-agree
	&__label-agree {
		position: relative;
		padding-left: 48px;
		cursor: pointer;

		&:before {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			content: "";
			width: 36px;
			height: 36px;
			background: $light-grey;
			border-radius: 4px;
			border: 1px solid transparent;
		}
	}

	// .form__input-agree
	&__input-agree:checked+.form__label-agree {
		&::after {
			position: absolute;
			top: 50%;
			left: 4px;
			transform: translateY(-50%);
			content: "";
			width: 28px;
			height: 20px;
			border-radius: 4px;
			background-image: url(../images/agreement.svg);
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}
	}

	// .form__textarea
	&__textarea {
		max-height: 86px;
		height: 86px;
	}


	// .form__agreement
	&__agreement {}


	// .form__btn
	&__btn {
		width: 100%;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border: 0;
		background-color: $brand-red;
		border-radius: 4px;
		padding: 12px;
		color: #fff;
		text-transform: uppercase;

		&:hover {
			background-color: #d40000;
		}

		&:disabled {
			background-color: rgb(137, 140, 147);
		}

		@media(min-width: 744px) {
			max-width: 306px;
		}
	}

	// .form__err
	&__err {
		border: 1px solid $brand-red;
	}

	// .form__err-agree
	&__err-agree {
		position: relative;

		&:before {
			position: absolute;
			content: "";
			border: 1px solid $brand-red;
		}
	}
}